@media (max-width: 960px) {
    .smaller-padding {
      padding: 3rem 7rem !important; /* Adjust padding for medium-sized screens */
    }
  }
  
  @media (max-width: 600px) {
    .smaller-padding {
      padding: 3rem 3rem !important; /* Adjust padding for small screens */
    }
  }

  .logo-image {
    max-width: 400px; /* Default maxWidth for larger screens */
}

@media (max-width: 960px) {
    .logo-image {
        max-width: 300px; /* Alter maxWidth for screens with a width of 960px or less */
    }
}

@media (max-width: 600px) {
    .logo-image {
        max-width: 250px; /* Alter maxWidth for screens with a width of 600px or less */
    }
}